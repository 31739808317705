/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'megaphone-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13 2.5a1.5 1.5 0 013 0v11a1.5 1.5 0 01-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 011.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 000 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 011.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 01-.983 1.187h-.548a1 1 0 01-.916-.599l-1.314-2.48a66 66 0 011.692.064q.491.026.966.06"/>',
    },
});
